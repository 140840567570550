.note {
  width: 494px;
  height: 40px;
  font-family: SegoeUI;
  font-size: 25px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #757575; }

.login_left.forgot {
  margin-top: 30px; }
  .login_left.forgot .head img {
    max-width: 500px; }
  .login_left.forgot .effect1 .inputEmail {
    background: url(/Email-f5765624.png) no-repeat !important;
    background-position-y: 64% !important;
    padding-left: 13%; }
  .login_left.forgot .effect1 .inputEmail.pass {
    background: url(/pass-ebb655a8.png) no-repeat !important;
    background-position-y: 64% !important;
    padding-left: 13%; }
  .login_left.forgot .button #button2 {
    line-height: normal !important; }

@media only screen and (max-width: 720px) and (min-width: 320px) {
  .note {
    font-size: 16px; }
  .effect1 .inputEmail {
    padding-left: 20% !important; }
  .login_left.forgot .effect1 .inputEmail {
    background-size: 25px !important;
    padding-left: 30px !important; } }

.content {
  margin: 20px 0; }
