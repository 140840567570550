body {
  width: 100vw;
  height: 100vh;
  margin: 0px;
  padding: 0px; }

.txt_wifi {
  color: #fd7506; }

.txt_color {
  color: #9a9a9a; }

.btn_wifi {
  background-color: #fd7506 !important; }

.bg_icons {
  background-color: #949494; }

.btn_guest {
  background-color: #d15e00; }

.cursor_pointer {
  cursor: pointer; }

.photo #btn_check a:hover {
  text-decoration: none; }

.photo div:first-child {
  overflow: hidden; }

.bg_notity {
  top: 0px;
  left: 0px;
  background: #000000;
  z-index: 6; }

.photo div:first-child .img_border {
  background-image: url(/border_circle-2ef78ef0.png);
  background-position: center;
  background-size: 93%;
  background-repeat: no-repeat;
  z-index: 3; }

.photo div:first-child .abc {
  bottom: 0px;
  left: 0px;
  z-index: 2; }

.photo div:first-child video {
  -o-object-fit: cover;
  object-fit: cover; }

.min-width {
  min-width: 100% !important; }

.min-height {
  min-height: 100% !important; }

.photo .small_btn {
  z-index: 5; }

.video_size {
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  min-width: 100%; }

.webcam {
  -webkit-border-radius: 50% !important;
  border-radius: 50% !important; }

@media (min-width: 300px) and (max-width: 767px) {
  .box_cam {
    width: 100%;
    margin: 5% 0 7% 0; }
  .video_size {
    border-radius: 50%;
    -webkit-border-radius: 50%; }
  .bg_on_mobile {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 101%;
    height: 101%;
    background: radial-gradient(circle at center 50%, transparent, transparent 70%, white 50px);
    -webkit-filter: drop-shadow(0px 0px 0px white);
    filter: drop-shadow(0px 0px 0px white);
    overflow: hidden;
    z-index: 4; } }

@media (min-width: 300px) and (max-width: 767px) and (max-height: 650px) {
  .box {
    height: 100%; }
  .photo > div:first-child {
    width: 85%; }
  .photo > div:first-child .img_border {
    background-image: url(/border_circle-2ef78ef0.png);
    background-position: center;
    background-size: 93%;
    background-repeat: no-repeat; }
  .photo .small_btn {
    bottom: 6%; }
  .photo.small_btn .icon-camera,
  .photo .small_btn .icon-checked {
    font-size: 2rem; } }

@media (min-width: 300px) and (max-width: 767px) and (min-height: 650px) {
  .box {
    height: 100%; }
  .photo > div:first-child {
    width: 85%; }
  .photo > div:first-child .img_border {
    background-image: url(/border_circle-2ef78ef0.png);
    background-position: center;
    background-size: 93%;
    background-repeat: no-repeat; } }

@media (min-width: 300px) and (max-width: 767px) and (min-height: 650px) and (max-height: 750px) {
  .photo .small_btn {
    bottom: 0%; }
  .photo .small_btn .icon-camera,
  .photo .small_btn .icon-checked {
    font-size: 2.1rem; } }

@media (min-width: 300px) and (max-width: 767px) and (min-height: 750px) {
  .photo .small_btn {
    bottom: 2%; }
  .photo .small_btn .icon-camera,
  .photo .small_btn .icon-checked {
    font-size: 2.4rem; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .box {
    height: 100%; }
  .box_cam {
    width: 70%; }
  .video_size {
    border-radius: 50%;
    -webkit-border-radius: 50%; }
  .photo > div:first-child {
    width: 65%; }
  .photo .small_btn {
    bottom: -2%;
    padding: 35px !important; }
  .photo .small_btn .icon-camera,
  .photo .small_btn .icon-checked {
    font-size: 3.5rem; } }

@media only screen and (min-width: 1024px) and (max-width: 1199px) and (max-height: 800px) {
  .photo > div:first-child {
    width: 65%; }
  .photo .small_btn {
    bottom: -57px;
    padding: 35px !important; }
  .photo .small_btn .icon-camera,
  .photo .small_btn .icon-checked {
    font-size: 3.5rem; } }

@media only screen and (min-width: 1024px) and (max-width: 1199px) and (min-height: 801px) {
  .box {
    height: 100%; }
  .box_cam {
    width: 75%; }
  .photo > div:first-child {
    width: 70%; }
  .photo .small_btn {
    bottom: 2%;
    padding: 37px !important; }
  .photo .small_btn .icon-camera,
  .photo .small_btn .icon-checked {
    font-size: 4.5rem; } }

@media only screen and (min-width: 1200px) {
  body .box {
    max-width: 1920px;
    max-height: 900px;
    width: 92%; }
  .photo .box_cam {
    width: 88%; }
  .photo .small_btn {
    bottom: -43px; }
  .photo .small_btn .icon-camera,
  .photo .small_btn .icon-checked {
    font-size: 3.4rem; } }
