body .btn-group button {
  margin: 0 !important; }

.photo div:first-child .img_border {
  background-image: none !important;
  background-position: center;
  background-size: 93%;
  background-repeat: no-repeat;
  z-index: 3; }

div#box_cam {
  background-position: center;
  background-size: 93%;
  background-repeat: no-repeat;
  z-index: 3; }
