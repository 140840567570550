* {
  margin: 0;
  padding: 0; }

body {
  height: 100vh; }

.cursor-pointer {
  cursor: pointer; }

.contenner {
  max-width: 1920px;
  width: 92%;
  margin: auto;
  /*min-height: 100%;*/ }

.login_page .head img {
  width: 90%;
  padding-top: 10px; }

.login_page .login_left {
  margin-left: 150px; }

.login_page .content input {
  width: 90%;
  padding-top: 30px;
  outline: 0px;
  border: 0px;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 1px solid gray;
  -webkit-box-shadow: none; }

.login_page input ~ .focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 90%;
  height: 2px;
  z-index: 1; }

.login_page input ~ .focus-border:before,
.login_page input ~ .focus-border:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #fd7508;
  transition: 0.4s; }

.login_page input ~ .focus-border:after {
  left: auto;
  right: 0; }

.login_page input:focus ~ .focus-border:before,
.login_page input:focus ~ .focus-border:after {
  width: 50%;
  transition: 0.4s; }

.login_page .content input[type="text"] {
  background: url(/user-b77f711b.png) no-repeat;
  background-size: 7%;
  background-position: 0 35px;
  padding-bottom: 18px;
  padding-left: 45px;
  position: relative;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0px; }

.login_page .content input[type="password"] {
  background: url(/pass-ebb655a8.png) no-repeat;
  background-size: 7%;
  background-position: 0 35px;
  padding-bottom: 18px;
  padding-left: 45px;
  position: relative;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0px; }

.login_page .button input, .login_page .button button {
  color: white;
  border: none;
  margin-top: 35px;
  margin-left: 5px; }

.login_page .button #button1 {
  width: 42.5%;
  height: 60px; }

.login_page .button #button2 {
  width: 42.5%;
  height: 60px; }

.import {
  padding-left: 8%;
  position: absolute; }

.login_page .button #button1 {
  width: 50%;
  background-color: #fd7508; }

.login_page .button #button2 {
  width: 50%;
  background-color: #d15e00; }

.login_page .faceid a:hover {
  text-decoration: none; }

.login_page .faceid img {
  width: 10%;
  padding-top: 25px;
  margin-left: 143px; }

.login_page .faceid p {
  color: gray;
  font-size: 15px;
  padding-left: 95px; }

.login_right {
  padding-top: 50px; }

.login_right img {
  width: 80%; }

.footer p {
  color: gray;
  font-size: 12px;
  padding-left: 320px;
  padding-top: 8%; }

.loading_component {
  background-color: #3d3c3c7d;
  z-index: 1; }

/* needed for effect */
.ErrorsContent {
  /*display: none;*/
  /*position: absolute;*/ }

/* optional shadow */
.loginpage_showErrors {
  position: absolute;
  border-radius: 2px;
  color: red;
  -moz-box-shadow: 0 0 8px #5b7d83;
  -webkit-box-shadow: 0 0 8px #5b7d83;
  box-shadow: 0 0 8px #5b7d83;
  font-size: 12px; }

.fa-check-circle:before {
  color: #fd7508;
  font-size: 150px; }

.btnNext {
  color: white;
  width: 50%;
  border-radius: 11px;
  border: 0px; }

.btn_copy:active {
  background-color: #ffa256; }

.bg_notity {
  top: 0px;
  left: 0px;
  background: #000000;
  z-index: 6; }

.fa-check-circle:before {
  color: #fd7508;
  content: "\f058";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 100px;
  background: #fff;
  border-radius: 50%; }

.success_opp span {
  font-size: 30px; }

.btnNext {
  color: white;
  width: 100%;
  height: 15%;
  border-radius: 15px;
  border: 0px; }

@media only screen and (max-width: 351px) {
  .height-top {
    height: 65% !important; }
  .height-bot {
    height: 35% !important; } }

@media only screen and (min-width: 320px) and (max-width: 720px) {
  .height-top {
    height: 60%; }
  .height-bot {
    height: 40%; }
  .login_page .head img {
    width: 100%;
    padding-top: 10px; }
  .login_page .login_left {
    padding: 0 20px 0 20px;
    margin: 0;
    text-align: center;
    /*margin-top: 5%;*/ }
  .login_page .content input {
    padding-top: 10px; }
  .login_page .content input[type="text"], .login_page .content input[type="password"] {
    background-size: 8%;
    background-position: 0px 10px;
    padding-bottom: 6px;
    width: 100%; }
  .login_page .button input {
    margin-top: 10px; }
  .login_page .button #button1, .login_page .button #button2 {
    width: 100%;
    margin-left: 0;
    margin-top: 5%;
    height: 40px; }
  .login_page .faceid {
    text-align: center; }
  .login_page .faceid img {
    padding-top: 10px;
    margin-left: 0;
    padding-left: 0; }
  .login_page .faceid p {
    font-size: 10px;
    padding: 0;
    margin-left: 0; }
  .login_right {
    text-align: center;
    padding-top: 0px; }
  .login_right img {
    width: 83%; }
  .login_right p {
    font-size: 9px;
    padding-top: 10px; }
  .footer p {
    padding: 5px 0 0 0;
    margin-bottom: 0; }
  .login_page input ~ .focus-border {
    height: 3px;
    width: 100%; }
  .loginpage_showErrors {
    right: -10%;
    top: 42px;
    font-size: 9px; }
  .success_opp .modal_box {
    width: 300px; }
  .success_opp .modal_box p {
    padding-top: 15px; }
  .fa-check-circle:before {
    font-size: 70px; }
  .btnNext {
    padding: 4px; } }

@media only screen and (min-width: 411px) and (min-height: 731px) {
  .general {
    width: 90%;
    margin: auto; }
  .login_page .head img {
    width: 100%;
    padding-top: 10px; }
  .login_page .login_left {
    padding: 0 20px 0 20px;
    margin: 0;
    text-align: center; }
  .login_page .content input {
    padding-top: 15px; }
  .login_page .content input[type="text"], .login_page .content input[type="password"] {
    background-size: 8%;
    background-position: 0px 15px;
    padding-bottom: 6px; }
  .login_page .button input {
    margin-top: 25px; }
  .login_page .button #button1, .login_page .button #button2 {
    width: 100%; }
  .login_page .faceid {
    text-align: center; }
  .login_page .faceid img {
    padding-top: 10px;
    margin-left: 0;
    padding-left: 0; }
  .login_page .faceid p {
    padding: 0;
    margin-left: 0; }
  .login_right {
    text-align: center;
    padding-top: 0px; }
  .login_right img {
    width: 80%; }
  .login_right p {
    font-size: 10px; }
  .login_page input ~ .focus-border {
    height: 3px;
    width: 100%;
    /*margin-left: 13px;*/ }
  .loginpage_showErrors {
    right: -4%;
    top: 52px;
    font-size: 9px; }
  .footer p {
    padding: 10px 0 0 0; } }

@media only screen and (min-width: 411px) and (max-width: 760px) {
  .general {
    width: 100%;
    margin: auto; }
  .login_page .head img {
    width: 100%;
    padding-top: 10px; }
  .login_page .login_left {
    padding: 0 8% 0 8%;
    margin: 0;
    text-align: center;
    /*margin-top: 5%;*/ }
  .login_page .content input {
    padding-top: 15px; }
  .login_page .content input[type="text"], .login_page .content input[type="password"] {
    width: 100%;
    background-size: 7%;
    background-position: 0px 70%;
    padding-bottom: 6px;
    font-size: 20px; }
  .login_page .button #button1, .login_page .button #button2 {
    width: 100%;
    height: 45px; }
  .login_page .faceid {
    text-align: center; }
  .login_page .faceid img {
    width: 10%;
    padding-top: 10px;
    margin-left: 0;
    padding-left: 0; }
  .login_page .faceid p {
    padding: 0;
    margin-left: 0; }
  .login_right {
    text-align: center;
    padding-top: 0px; }
  .login_right img {
    width: 82%;
    padding-top: 1%; }
  .login_right p {
    font-size: 15px; }
  .footer p {
    font-size: 10px;
    padding: 10px 0 0 0; }
  .login_page input ~ .focus-border {
    height: 3px;
    width: 100%; }
  .loginpage_showErrors {
    right: -4%;
    top: 52px;
    font-size: 9px; } }

/*IPAD*/
@media only screen and (min-width: 761px) and (max-width: 1023px) {
  .general {
    width: 100%;
    margin: auto; }
  .height-top {
    height: 60%; }
  .height-bot {
    height: 40%; }
  .login_page .head img {
    width: 70%; }
  .login_page .login_left {
    padding: 0 20px 0 20px;
    margin: 0;
    text-align: center;
    margin-top: 5%; }
  .login_page .content input {
    width: 70%;
    margin: 15px 0px; }
  .login_page .button input, .login_page .button button {
    color: white;
    border: none;
    margin-top: 20px;
    margin-left: 5px; }
  .login_page .content input[type="text"], .login_page .content input[type="password"] {
    background-position: 0 60%;
    padding-left: 60px;
    font-size: 24px; }
  .login_page .button #button1, .login_page .button #button2 {
    width: 70%;
    height: 45px; }
  .login_page .faceid {
    text-align: center; }
  .login_page .faceid img {
    width: 7%;
    padding-top: 10px;
    margin-left: 0;
    padding-left: 0; }
  .login_page .faceid p {
    padding: 0;
    margin-left: 0; }
  .login_right {
    text-align: center;
    padding-top: 0px; }
  .login_right img {
    width: 60%;
    margin-left: -30px; }
  .footer p {
    font-size: 15px;
    padding: 10px 0 0 0; }
  .login_page input ~ .focus-border {
    width: 71%;
    height: 3px;
    margin-left: 100px; }
  .login_page input ~ .focus-border:before, .login_page input ~ .focus-border:after {
    bottom: 14px; }
  .loginpage_showErrors {
    right: 0%;
    top: 69px;
    font-size: 9px; }
  .btnNext {
    font-size: 22px; } }

/*IPAD PRO*/
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .height-top {
    height: 60%; }
  .height-bot {
    height: 40%; }
  .general {
    width: 100%; }
  .login_page .head img {
    width: 70%; }
  .login_page .login_left {
    padding: 0 20px 0 20px;
    margin: 0;
    text-align: center;
    margin-top: 5%; }
  .login_page .content input {
    width: 70%;
    height: 80px; }
  .login_page .button input {
    margin-top: 30px; }
  .login_page .button #button1, .login_page .button #button2 {
    font-size: 30px;
    width: 70%;
    height: 60px; }
  .login_page .content input[type="text"] {
    font-size: 30px;
    padding-left: 10%; }
  .login_page .content input[type="password"] {
    font-size: 30px;
    padding-left: 10%; }
  .login_page .faceid {
    text-align: center; }
  .login_page .faceid img {
    padding-top: 10px;
    margin-left: 0;
    padding-left: 0; }
  .login_page .faceid p {
    padding: 0;
    margin-left: 0; }
  .login_right {
    text-align: center;
    padding-top: 0px; }
  .login_right img {
    width: 65%;
    margin-left: -50px;
    margin-top: 5%; }
  .footer p {
    font-size: 20px;
    padding: 10px 0 0 0; }
  .login_page input ~ .focus-border {
    width: 70%;
    height: 2px;
    margin-left: 135px; }
  .loginpage_showErrors {
    right: 0%;
    top: 82px;
    font-size: 15px; }
  .btnNext {
    font-size: 25px; } }

/* Iphone X*/
@media only screen and (max-width: 720px) and (min-width: 320px) and (min-height: 812px) {
  .contenner {
    width: 100%; }
  .height-top {
    height: 60% !important;
    position: absolute;
    top: 0px; }
  .height-bot {
    height: 40%;
    position: absolute;
    bottom: 0px; }
  .general {
    width: 100vw;
    height: 100vh;
    align-items: center; }
  .login_page .head img {
    width: 100%;
    /*padding-top: 10%;*/ }
  .login_page .content input {
    width: 100%; }
  .login_page .button #button1 {
    width: 100%; }
  .login_page .button #button2 {
    width: 100%; }
  .login_page .faceid img {
    width: 15%;
    padding-top: 10px; }
  .login_right img {
    width: 83%;
    padding-top: 0; }
  .login_page input ~ .focus-border {
    height: 3px;
    width: 100%; }
  .loginpage_showErrors {
    right: -4%;
    top: 42px;
    font-size: 9px; } }

/*man hinh lon */
@media only screen and (min-width: 1201px) {
  .contenner {
    max-width: 1920px;
    width: 92%;
    margin: auto; }
  .login_page .head img {
    width: 85%; }
  .general {
    width: 100%;
    height: 100vh;
    align-items: center;
    padding-left: 5%; }
  .login_page .login_left {
    padding: 10% 20px 0 20px;
    margin: 0;
    text-align: center; }
  .login_page .content input {
    height: 80px;
    margin-top: 3%; }
  .login_page .button {
    padding-top: 5%; }
  .login_page .content input[type="text"] {
    background-position: 0 28px; }
  .login_page .content input[type="password"] {
    background-position: 0 28px; }
  .login_page .button #button1 {
    width: 42.5%;
    font-size: 30px;
    height: 60px;
    color: white; }
  .login_page .button #button2 {
    width: 42.5%;
    font-size: 30px;
    height: 60px;
    color: white; }
  .login_page .content input[type="text"] {
    width: 85%;
    font-size: 30px;
    padding-left: 10%; }
  .login_page .content input[type="password"] {
    width: 85%;
    font-size: 30px;
    padding-left: 10%; }
  .login_page .faceid {
    text-align: center; }
  .login_page .faceid img {
    padding-top: 30px;
    margin-left: 0;
    padding-left: 0; }
  .login_page .faceid p {
    font-size: 15px;
    opacity: 0.7;
    padding: 0;
    margin-left: 0; }
  .login_right {
    text-align: center;
    padding-top: 0px; }
  .login_right img {
    height: 80%;
    width: 95%;
    padding-top: 10%; }
  .footer p {
    text-align: center;
    font-size: 15px;
    padding: 5% 0 0 35%; }
  .login_page input ~ .focus-border {
    width: 85.5%;
    height: 3px;
    margin-left: 7%; }
  .loginpage_showErrors {
    top: 95px;
    font-size: 12px; } }
