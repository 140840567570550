@font-face {
  font-family: MyWebFont_bold;
  src: url(/HelveticaNeueLight-0facaae9.ttf); }

body {
  height: 100vh; }

.container {
  max-width: 1920px !important;
  width: 92%;
  margin: auto; }

.txt_wifi {
  color: #fd7506; }

.txt_color {
  color: #9a9a9a; }

.btn_wifi {
  background-color: #fd7506; }

.bg_icons {
  background-color: #949494; }

.btn_guest {
  background-color: #d15e00; }

.cursor_pointer {
  cursor: pointer; }

.bg_opp {
  width: 100vw;
  height: 100vh !important;
  position: fixed;
  background: rgba(12, 12, 12, 0.85);
  z-index: 2; }

.bg_opp .modal_box {
  border-radius: 31px;
  width: 90%;
  max-width: 400px; }

.bg_opp .modal_box i {
  top: 18px;
  right: 18px;
  font-size: 26px; }

.bg_opp .modal_box img {
  width: 60%; }

.bg_opp .modal_box p {
  font-family: 'HelveticaNeu_Bold';
  font-size: 20px;
  font-weight: bold; }

.register_section > div > p {
  font-family: 'MyWebFont_bold';
  font-size: 5rem;
  letter-spacing: 1px;
  line-height: 100%; }

.register_section > div form .effect-17,
.register_section > div form .select_gender {
  border: 0px;
  border-bottom: 1px solid #9a9a9a;
  outline: 0px;
  padding-left: 55px;
  padding-bottom: 14px;
  color: #959595;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  font-family: 'MyWebFont_bold'; }

.success_opp .modal_box i {
  top: 18px;
  right: 18px;
  font-size: 26px; }

.success_opp {
  width: 100vw;
  height: 100vh !important;
  position: fixed;
  background: rgba(12, 12, 12, 0.85);
  z-index: 2; }

.success_opp .modal_box {
  border-radius: 15px;
  width: 400px;
  height: 270px; }

.success_opp .modal_box img {
  width: 60%; }

.success_opp .modal_box p {
  font-family: 'HelveticaNeu_Bold';
  font-size: 25px;
  font-weight: bold; }

.success_opp .modal_box i {
  top: 18px;
  right: 18px;
  font-size: 26px; }

.popover-content {
  display: none; }

.popover {
  -moz-box-shadow: 0 0 8px #5b7d83;
  -webkit-box-shadow: 0 0 8px #5b7d83;
  box-shadow: 0 0 8px #5b7d83; }

.select {
  border: 0px;
  border-bottom: 1px solid #9a9a9a;
  outline: 0px;
  padding-left: 55px;
  padding-bottom: 14px;
  color: #959595;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  padding-top: 4px;
  font-family: 'MyWebFont_bold'; }

.select span {
  font-family: 'MyWebFont_bold';
  font-size: 1rem;
  letter-spacing: 1px;
  line-height: 100%;
  color: #959595;
  width: 100%; }

.gender-section {
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  font-size: 1rem;
  color: #212529;
  list-style: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: .25rem;
  text-align: center; }

li:hover {
  /*background-color:#9a9a9a;*/
  color: #fd7506; }

.register_section > div form .password ~ label:nth-child(2) {
  opacity: 1; }

.register_section > div form .password ~ label:nth-child(3) {
  opacity: 0; }

.register_section > div form .password:focus ~ label:nth-child(2) {
  opacity: 0; }

.register_section > div form .password:focus ~ label:nth-child(3) {
  opacity: 1; }

.register_section > div form input ~ span,
.register_section > div form select ~ span {
  bottom: 15px;
  left: 8px;
  font-size: 34px;
  color: #959595; }

#import {
  position: absolute; }

.register_section > div form .icon-camera_circle {
  font-size: 46px; }

.cam {
  padding-top: 2%; }

.fas {
  color: #949494; }

.icon_gender {
  border: 2px solid #949494;
  font-size: 25px;
  padding: 6px 1px 0px 1px;
  overflow: hidden; }

.register_section > div form .icon_phone {
  border: 2px solid #949494;
  font-size: 26px;
  padding: 5px 7px 0px 7px;
  overflow: hidden; }

.icon_camera {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
  margin: auto;
  width: 56px;
  height: 53px;
  border: 1px solid #949494;
  font-size: 42px;
  padding: 14px 0px 0px 6px;
  overflow: hidden;
  color: #959595; }

.register_section .bg_edit {
  position: absolute;
  color: #ffffff80;
  background-color: #00000069;
  height: 40%;
  bottom: 0px; }

.register_section .edit {
  font-size: 12px; }

.icon_camera i {
  margin-bottom: -3px; }

.register_section > div button {
  font-size: 1.6rem; }

.register_section > div #btn_back {
  border: 1px solid gray;
  color: #949494; }

.loading_component {
  background-color: #3d3c3c7d;
  z-index: 1; }

.register_showErrors {
  position: absolute;
  border-radius: 2px;
  color: red;
  -moz-box-shadow: 0 0 8px #5b7d83;
  -webkit-box-shadow: 0 0 8px #5b7d83;
  box-shadow: 0 0 8px #5b7d83;
  font-size: 12px; }

@media (max-width: 350px) {
  .box_cam {
    margin: 5% 0 14% 0 !important; }
  /*.photo .small_btn {*/
  /*    bottom: 8px!important;*/
  /*}*/ }

@media (min-width: 300px) and (max-width: 1023px) {
  .bg_opp img {
    width: 65%; }
  .bg_opp .modal_box {
    border-radius: 31px;
    width: 90%;
    max-width: 200%; }
  .container {
    height: 100%;
    width: 100%; }
  .register_section > div {
    padding: 2% 3% 0% 3%;
    margin-top: 2%; }
  .register_section > div > p {
    font-size: 3.2rem; }
  .register_section > div input {
    font-size: 12px; }
  .register_section > div input ~ label {
    font-size: 12px; }
  .register_section > div form .effect-17,
  .register_section > div form .select_gender {
    padding-left: 40px;
    padding-bottom: 10px; }
  .register_section > div form img {
    width: 50px; }
  .register_section > div form .input span {
    font-size: 20px !important; }
  .register_section > div form .icon_gender {
    font-size: 15px !important;
    border: 1.5px solid #949494; }
  .register_section > div form .icon_phone {
    font-size: 15px !important;
    padding: 5px 5px 0px 5px;
    border: 1.5px solid #949494; }
  .icon_camera {
    height: 49px;
    font-size: 35px !important;
    width: 50px;
    padding: 15px 0px 0px 6px; }
  .register_section > div form .phone {
    -webkit-box-shadow: none;
    box-shadow: none; }
  .register_section > div button {
    font-size: 1rem; }
  .register_section > div input ~ label {
    display: none; }
  .register_showErrors {
    font-size: 9px;
    right: -8%; }
  .select {
    font-size: 12px;
    padding-left: 35px; }
  .success_opp .modal_box {
    width: 300px;
    height: auto; }
  .success_opp .modal_box p {
    padding-top: 15px;
    font-size: 20px; }
  .bg_opp .modal_box p {
    padding-top: 15px; } }

@media (min-width: 300px) and (max-width: 1023px) {
  .register_section > div form input ~ span,
  .register_section > div form select ~ span {
    bottom: 11px; } }

@media (min-width: 300px) and (max-width: 1023px) and (max-height: 650px) {
  .bg_opp img {
    width: 65%; }
  .bg_opp .modal_box {
    border-radius: 31px;
    width: 90%;
    max-width: 100%;
    font-size: 14px; }
  .register_section > div {
    padding: 0% 5% 0% 5%;
    margin-top: 2%; }
  .register_section > div form .effect-17,
  .register_section > div form .select_gender {
    padding-bottom: 8px; }
  .register_section #btn_back {
    border: 2px solid #94949491; }
  .cam {
    padding-top: 0; }
  #btn_back {
    padding: 4px !important; }
  #btn_done {
    padding: 4px !important; } }

@media (min-width: 300px) and (max-width: 1023px) and (min-height: 800px) {
  .register_section > div input {
    font-size: 16px; }
  .register_section > div form .effect-17,
  .register_section > div form .select_gender {
    padding-bottom: 8px;
    padding-left: 39px; }
  .register_section > div form input ~ span,
  .register_section > div form select ~ span {
    bottom: 8px;
    left: 5px; }
  .register_section > div form .input span {
    font-size: 27px !important; }
  .register_section > div form .icon_gender,
  .register_section > div form .icon_phone {
    font-size: 18px !important; }
  .icon_camera {
    height: 50px;
    font-size: 38px !important;
    width: 50px;
    padding: 13px 0px 0px 5px; }
  .register_section > div form .phone {
    -webkit-box-shadow: none;
    box-shadow: none; }
  .register_section#btn_back {
    border: 2px solid #94949491; }
  .bg_opp .modal_box img {
    width: 50%; }
  .bg_opp .modal_box p {
    font-size: 35px;
    padding-top: 20px; } }

@media (min-width: 300px) and (max-width: 677px) and (min-height: 800px) {
  .register_section > div {
    padding: 0% 8% 0% 8%; }
  .cam {
    padding-top: 3%; }
  .bg_opp .modal_box p {
    font-size: 22px; } }

@media (min-width: 678px) and (max-width: 1023px) and (min-height: 800px) {
  .bg_opp img {
    width: 70%; }
  .bg_opp .modal_box {
    border-radius: 31px;
    width: 90%;
    max-width: 600px;
    font-size: 30px; }
  .register_section > div {
    padding: 6% 9% 0% 9%; }
  .register_section > div > p {
    font-size: 4.7rem; }
  .login_right > div {
    text-align: center; }
  .register_showErrors {
    font-size: 13px;
    right: -15%; }
  .select {
    font-size: 15px; }
  .success_opp .modal_box {
    width: 500px; }
  .success_opp .modal_box p {
    font-size: 40px; }
  .btnNext {
    padding: 5px; } }

@media only screen and (min-width: 1024px) and (max-width: 1199px) and (min-height: 1000px) {
  .bg_opp img {
    width: 70%; }
  .bg_opp .modal_box {
    border-radius: 31px;
    width: 90%;
    max-width: 600px; }
  .register_section > div {
    padding: 0px 36px 0px 36px;
    margin-top: 10%; }
  .register_section > div > p {
    font-size: 7rem; }
  .register_section > div input,
  .register_section > div select {
    font-size: 32px; }
  .register_section > div form .effect-17 {
    padding-left: 55px; }
  .register_section > div form .select_gender {
    padding-bottom: 14px;
    padding-left: 52px; }
  .register_section > div form input ~ span,
  .register_section > div form select ~ span {
    bottom: 20px;
    left: 5px;
    font-size: 39px; }
  .register_section > div form .icon_gender,
  .register_section > div form .icon_phone,
  .register_section > div form a {
    font-size: 28px !important; }
  .register_section > div form .icon_phone {
    padding: 5px 9px 0px 9px; }
  .icon_camera {
    font-size: 68px !important;
    width: 88.9px;
    height: 80.9px;
    padding: 17px 0px 0px 9px; }
  .register_section > div form .phone {
    -webkit-box-shadow: none;
    box-shadow: none; }
  .register_section > div input ~ label {
    display: none; }
  .register_showErrors {
    font-size: 13px;
    right: -7%; }
  .select {
    font-size: 26px; }
  .success_opp .modal_box {
    width: 500px;
    height: 350px; }
  .success_opp .modal_box p {
    font-size: 35px;
    margin-top: 30px; }
  .btnNext {
    padding: 5px; } }

@media only screen and (min-width: 1200px) {
  .register_section > div {
    padding: 7% 0 0 12%; }
  .mb-xl-5, .my-xl-5 {
    margin-top: 2rem !important; }
  .mt-xl-5, .my-xl-5 {
    margin-top: 2rem !important; } }
